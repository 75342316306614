import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';

export interface Option {
  label: string | number;
  value: string | number;
}

interface IProps {
  options: Option[];
  control: Control;
  name: string;
  onChange?: () => void;
  placeholder?: string;
  ref?: any;
}

const CustomSelect: React.FC<IProps> = ({
  options,
  control,
  name,
  placeholder = 'Escoge una opción',
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: 'Debes escoger una opción' }}
      render={({ field: { onChange, ref }, fieldState: { invalid } }) => (
        <Select
          required
          isSearchable={false}
          options={options}
          onChange={({ value }: any) => onChange(value)}
          styles={{
            control: (base, props) => ({
              ...base,
              borderRadius: '10px',
              borderColor: invalid ? '#f44336' : props.isFocused ? '#FC0' : '#000',
              padding: '0 8px',
              lineHeight: '24px',
              height: 40,
              boxShadow: invalid ? 'none' : props.isFocused ? '0 0 0 1px #FC0' : 'none',
              ':hover': {
                borderColor: invalid ? '#f44336' : props.isFocused ? '#FC0' : 'grey',
              },
            }),
            menu: (base) => ({
              ...base,
              borderRadius: '10px',
              overflow: 'hidden',
            }),

            option: (base, props) => ({
              ...base,
              background: props.isFocused ? '#FFCC00' : '#fff',
              color: '#000',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              color: '#000',
              padding: 5,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
          }}
          placeholder={placeholder}
          ref={ref}
        />
      )}
    />
  );
};

export default CustomSelect;
