import { Box, Container, HStack, Image, Text, VStack } from '@chakra-ui/react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import LinkToRegister from '../components/LinkToRegister';
import Section from '../components/Section';

function App() {
  return (
    <>
      <Header />
      <Section>
        <Container>
          <VStack gap={59} flexDirection={{ base: 'column', md: 'row-reverse' }}>
            <VStack gap={{ base: 38, md: 42 }} alignItems="start">
              <Text variant="heading" as="h3">
                Ahorra dinero y <br />
                <Text as="span" variant="highlight">
                  paga el precio más bajo
                </Text>
                <br /> en cada compra
              </Text>
              <Text>
                Obtén descuentos, cupones y reintegros en un solo lugar para que nunca pierdas una
                oportunidad de ahorrar dinero.
              </Text>
              <LinkToRegister variant="black" size="lg" />
            </VStack>
            <Box flexShrink={0}>
              <Image src={require('../assets/banner.png')} alt="" />
            </Box>
          </VStack>
        </Container>
      </Section>

      <Section>
        <Container padding={0}>
          <HStack
            justifyContent="space-between"
            paddingBottom={68}
            paddingInline={4}
            overflow={'auto'}
          >
            <Image src={require('../assets/brands/Ellipse 1.png')} />
            <Image src={require('../assets/brands/Ellipse 2.png')} />
            <Image src={require('../assets/brands/Ellipse 3.png')} />
            <Image src={require('../assets/brands/Ellipse 5.png')} />
            <Image src={require('../assets/brands/Ellipse 6.png')} />
            <Image src={require('../assets/brands/Ellipse 7.png')} />
          </HStack>
          <VStack gap={{ base: 38, md: 42 }} paddingInline={4}>
            <Text variant="heading" as="h3" textAlign="center">
              <Text as="span" variant="highlight">
                Descuentos exclusivos
              </Text>
              <br /> para Argentina
            </Text>
            <Text textAlign="center">
              Ofrecemos descuentos exclusivos en tiendas en línea y minoristas en Argentina, lo que
              significa que tendrás más ofertas relevantes a las tiendas donde compras regularmente.
            </Text>
          </VStack>
        </Container>
      </Section>

      <Section black>
        <Container>
          <VStack gap={59} flexDirection={{ base: 'column-reverse', md: 'row' }}>
            <Image src={require('../assets/section3.png')} marginBottom="-50px" />
            <VStack gap={{ base: 38, md: 42 }}>
              <Text
                variant="heading"
                as="h3"
                color="white"
                textAlign={{ base: 'center', md: 'left' }}
                marginLeft={{ md: '-1em' }}
              >
                <Text as="span" variant="highlight">
                  Obtén reintegros de dinero
                </Text>
                <br />
                en tu banco o billetera cripto
              </Text>
              <Text color="white" textAlign={{ base: 'center', md: 'left' }}>
                No solo obtienes descuentos, sino que también recibes cash backs o reembolsos de un
                porcentaje de tus compras, directamente en tu cuenta bancaria local o en
                criptomonedas.
              </Text>
            </VStack>
          </VStack>
        </Container>
      </Section>

      <Section>
        <Container>
          <VStack gap={{ base: 38, md: 42 }} marginBottom={68}>
            <Text variant="heading" as="h3" textAlign="center">
              <Text as="span" variant="highlight">
                Descuentos bancarios y de clubes de beneficios
              </Text>
            </Text>
            <Text textAlign="center">
              Te haremos saber si puedes tener algún descuento adicional usando tus tarjetas
              bancarias o clubes de beneficios (como Club La Nación o Clarin 365) para que no
              vuelvas a perderte un descuento.
            </Text>
          </VStack>
          <HStack
            justifyContent="space-between"
            paddingBottom={68}
            paddingInline={4}
            overflow={'auto'}
          >
            <Image src={require('../assets/banks/image 1.png')} />
            <Image src={require('../assets/banks/image 2.png')} />
            <Image src={require('../assets/banks/image 3.png')} />
            <Image src={require('../assets/banks/image 4.png')} />
            <Image src={require('../assets/banks/image 5.png')} />
            <Image src={require('../assets/banks/image 6.png')} />
          </HStack>
        </Container>
      </Section>

      <Section black padding={{ md: '100px 0' }}>
        <Container>
          <VStack flexDirection={{ base: 'column', md: 'row' }} gap={42}>
            <VStack gap={{ base: 38, md: 42 }} flex={1}>
              <Text
                variant="heading"
                as="h3"
                color="white"
                textAlign={{ base: 'center', md: 'left' }}
                marginLeft={{ md: '-3em' }}
              >
                Fácil de usar y <br />
                <Text as="span" variant="highlight">
                  sin tiempo de búsqueda
                </Text>
              </Text>
              <Text color="white" textAlign={{ base: 'center', md: 'left' }}>
                Nuestra extensión es fácil de usar y te muestra los descuentos disponibles en el
                momento de la compra, lo que significa que no tendrás que perder tiempo buscando y
                que no volverás a olvidarte de usar un descuento disponible.
              </Text>
            </VStack>
            <Box flex={1}>
              <Image
                src={require('../assets/section4.png')}
                marginBottom={{ base: '-70px', md: '-240px' }}
              />
            </Box>
          </VStack>
        </Container>
      </Section>

      <Section>
        <Container>
          <VStack gap={{ base: 38, md: 42 }}>
            <Text variant="heading" as="h3" textAlign="center">
              <Text as="span" variant="highlight">
                Comienza a ahorrar dinero hoy
              </Text>
            </Text>
            <Text textAlign="center">
              Únete ahora y comienza a ahorrar dinero en tus compras. No pierdas más tiempo buscando
              descuentos, deja que nosotros lo hagamos por ti.
            </Text>
            <LinkToRegister variant="black" size="lg" />
          </VStack>
        </Container>
      </Section>
      <Footer />
    </>
  );
}

export default App;
