import { VStack, Button, Stack, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import CustomCheckbox from '../../components/Checkbox';
import useRegisterContext from '../../hooks/useRegisterContext';
import saveRegister from '../../services/form';
import { mostInterestedAreasOptions, mostInterestedDiscountsOptions } from './form-select-options';

export default function Step2() {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { email, changeStep } = useRegisterContext();

  const onSubmit = async (data: any) => {
    setLoading(true);
    await saveRegister({ ...data, email, update: true });

    changeStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack justify="center" gap={8}>
        <Stack gap={8}>
          <FormControl isInvalid={Boolean(errors.most_interested_discounts)}>
            <CustomCheckbox
              name="most_interested_discounts"
              control={control}
              label="¿Qué descuentos te interesan más?"
              options={mostInterestedDiscountsOptions}
              rules={{
                required: 'Debes escoger al menos una opción',
              }}
            />

            <FormErrorMessage>
              {errors.most_interested_discounts &&
                errors.most_interested_discounts.message?.toString()}
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.most_interested_areas)}>
            <CustomCheckbox
              name="most_interested_areas"
              control={control}
              label="¿Para que rubros te gustaría que consiguiéramos más descuentos?"
              options={mostInterestedAreasOptions}
              rules={{
                required: 'Debes escoger al menos una opción',
              }}
            />
            {errors.most_interested_areas && (
              <FormErrorMessage>
                {errors.most_interested_areas?.message?.toString()}
              </FormErrorMessage>
            )}
          </FormControl>
        </Stack>
        <Button type="submit" isLoading={loading}>
          Siguiente
        </Button>
      </VStack>
    </form>
  );
}
