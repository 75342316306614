import React from 'react';

export interface RegisterContextProps {
  step: number;
  email: string | undefined;
  changeStep: () => void;
  setEmail: (email: string) => void;
}

interface RegisterProviderProps {
  children: React.ReactNode;
}
const defaultState = {
  step: 0,
  email: undefined,
  changeStep() {},
  setEmail(email: string) {},
};

export const RegisterContext = React.createContext<RegisterContextProps>(defaultState);

export const RegisterProvider: React.FC<RegisterProviderProps> = ({ children }) => {
  const [step, setStep] = React.useState(defaultState.step);
  const [email, setEmail] = React.useState<string>();

  const changeStep = () => {
    setStep((current) => current + 1);
  };

  return (
    <RegisterContext.Provider
      value={{
        step,
        email,
        changeStep,
        setEmail,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};
