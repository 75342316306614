import { Box, BoxProps } from '@chakra-ui/react';

interface SectionProps extends BoxProps {
  children: React.ReactNode;
  black?: boolean;
}

export default function Section({
  children,
  black = false,
  ...props
}: SectionProps) {
  return (
    <Box
      paddingBlock="50px"
      marginBottom="50px"
      position="relative"
      background={black ? '#000' : '#fff'}
      {...props}
    >
      {children}
    </Box>
  );
}
