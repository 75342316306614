import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  VStack,
  Button,
  Grid,
  useToast,
} from '@chakra-ui/react';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import CustomSelect from '../../components/Select';
import useRegisterContext from '../../hooks/useRegisterContext';
import { yearListOptions } from './form-select-options';
import saveRegister from '../../services/form';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

export default function Step4() {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [hasBankCard, setHasBankCard] = useState(false);
  const [hasBenefitsClubs, setHasBenefitsClubs] = useState(false);

  const navigate = useNavigate();
  const toast = useToast();
  const { email } = useRegisterContext();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setLoading(true);
    await saveRegister({ ...data, email, update: true });
    navigate('/');
    toast({
      title: '¡Listo! Ya terminamos',
      description:
        'Tu ayuda con este formulario es super importante para el desarrollo de Descuentas.',
      status: 'success',
      isClosable: true,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <VStack justify="center" gap={8} flex={1}>
        <FormControl isInvalid={Boolean(errors.year_of_birth)}>
          <FormLabel>Año de nacimiento</FormLabel>
          <CustomSelect
            control={control}
            name="year_of_birth"
            options={yearListOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <FormErrorMessage>
            {errors.year_of_birth && errors.year_of_birth.message?.toString()}
          </FormErrorMessage>
        </FormControl>
        <Controller
          name="has_bank_card"
          control={control}
          rules={{ required: 'Selecciona una opción' }}
          render={({ field: { onChange, value } }) => (
            <FormControl isInvalid={Boolean(errors.has_bank_card)}>
              <RadioGroup
                onChange={(value) => {
                  setHasBankCard(value === 'Sí');
                  onChange(value);
                }}
                value={value}
              >
                <FormLabel>¿Tienes tarjeta bancaria?</FormLabel>
                <HStack>
                  <Radio value="Sí">Sí</Radio>
                  <Radio value="No">No</Radio>
                </HStack>
              </RadioGroup>
              <FormErrorMessage>
                {errors.has_bank_card && errors.has_bank_card.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          )}
        />

        {hasBankCard && (
          <Controller
            name="uses_card_discounts"
            control={control}
            rules={{ required: 'Selecciona una opción' }}
            render={({ field: { onChange, value } }) => (
              <FormControl isInvalid={Boolean(errors.has_benefits_clubs)}>
                <RadioGroup onChange={onChange} value={value}>
                  <FormLabel>¿Usas los descuentos que te ofrece?</FormLabel>
                  <Grid gridTemplateColumns={{ md: 'repeat(2, 1fr)' }} gap={2}>
                    <Radio value="No">No</Radio>
                    <Radio value="Poco">Poco</Radio>
                    <Radio value="De vez en cuando">De vez en cuando</Radio>
                    <Radio value="Frecuentemente">Frecuentemente</Radio>
                  </Grid>
                </RadioGroup>
                <FormErrorMessage>
                  {errors.uses_card_discounts && errors.uses_card_discounts.message?.toString()}
                </FormErrorMessage>
              </FormControl>
            )}
          />
        )}

        <Controller
          name="has_benefits_clubs"
          control={control}
          rules={{ required: 'Selecciona una opción' }}
          render={({ field: { onChange, value } }) => (
            <FormControl isInvalid={Boolean(errors.has_benefits_clubs)}>
              <RadioGroup
                onChange={(value) => {
                  setHasBenefitsClubs(value === 'Sí');
                  onChange(value);
                }}
                value={value}
              >
                <FormLabel>
                  ¿Tienes clubes de beneficios como Club Personal o Club La Nación?
                </FormLabel>
                <HStack>
                  <Radio value="Sí">Sí</Radio>
                  <Radio value="No">No</Radio>
                </HStack>
              </RadioGroup>
              <FormErrorMessage>
                {errors.has_benefits_clubs && errors.has_benefits_clubs.message?.toString()}
              </FormErrorMessage>
            </FormControl>
          )}
        />

        {hasBenefitsClubs && (
          <Controller
            name="uses_club_discounts"
            control={control}
            rules={{ required: 'Selecciona una opción' }}
            render={({ field: { onChange, value } }) => (
              <FormControl isInvalid={Boolean(errors.has_benefits_clubs)}>
                <RadioGroup onChange={onChange} value={value}>
                  <FormLabel>¿Usas los descuentos que ofrecen?</FormLabel>
                  <Grid gridTemplateColumns={{ md: 'repeat(2, 1fr)' }} gap={2}>
                    <Radio value="No">No</Radio>
                    <Radio value="Poco">Poco</Radio>
                    <Radio value="De vez en cuando">De vez en cuando</Radio>
                    <Radio value="Frecuentemente">Frecuentemente</Radio>
                  </Grid>
                </RadioGroup>
                <FormErrorMessage>
                  {errors.uses_club_discounts && errors.uses_club_discounts.message?.toString()}
                </FormErrorMessage>
              </FormControl>
            )}
          />
        )}
        <Button type="submit" isLoading={loading}>
          Finalizar
        </Button>
      </VStack>
    </form>
  );
}
