import { FormControl, FormErrorMessage, FormLabel, VStack, Button } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import CustomSelect from '../../components/Select';
import useRegisterContext from '../../hooks/useRegisterContext';
import {
  mostInterestedSolutionsOptions,
  mostUsedPaymentMethodsOptions,
  onlineShoppingFrecuencyOptions,
} from './form-select-options';
import saveRegister from '../../services/form';
import { useState } from 'react';

export default function Step3() {
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { email, changeStep } = useRegisterContext();

  const onSubmit = async (data: any) => {
    setLoading(true);
    await saveRegister({ ...data, email, update: true });
    changeStep();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <VStack justify="center" gap={8}>
        <FormControl isInvalid={Boolean(errors.most_interested_solutions)}>
          <FormLabel>¿En cuál de las siguientes soluciones estás más interesado?</FormLabel>
          <CustomSelect
            control={control}
            name="most_interested_solutions"
            options={mostInterestedSolutionsOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <FormErrorMessage>
            {errors.most_interested_solutions &&
              errors.most_interested_solutions.message?.toString()}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.most_used_payment_methods)}>
          <FormLabel>¿Qué medio de pago usas mayormente para tus compras?</FormLabel>
          <CustomSelect
            control={control}
            name="most_used_payment_methods"
            options={mostUsedPaymentMethodsOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <FormErrorMessage>
            {errors.most_used_payment_methods &&
              errors.most_used_payment_methods.message?.toString()}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.online_shopping_frecuency)}>
          <FormLabel>¿Con qué frecuencia compras a través de Internet?</FormLabel>
          <CustomSelect
            control={control}
            name="online_shopping_frecuency"
            options={onlineShoppingFrecuencyOptions.map((option) => ({
              label: option,
              value: option,
            }))}
          />
          <FormErrorMessage>
            {errors.online_shopping_frecuency &&
              errors.online_shopping_frecuency.message?.toString()}
          </FormErrorMessage>
        </FormControl>

        <Button type="submit" isLoading={loading}>
          Siguiente
        </Button>
      </VStack>
    </form>
  );
}
