const mostInterestedDiscountsOptions = [
  'Cupones',
  'Reintegros en efectivo',
  'Reintegros en cripto',
  'Beneficios bancarios',
  'Beneficios de mis clubes',
];

const mostInterestedAreasOptions = [
  'Supermercado',
  'Tecnología y electrodomésticos',
  'Hogar y muebles',
  'Herramientas',
  'Ropa y calzado',
  'Cine y entretenimiento',
  'Maquillaje y cuidado personal',
  'Deporte y aire libre',
  'Bebés y mamás',
  'Mascotas',
  'Comidas y restaurantes',
  'Librerías',
];

const mostInterestedSolutionsOptions = [
  'Sitio web',
  'Aplicación movil',
  'Extensión de Chrome para la computadora',
  'Consultas de descuento via WhatsApp',
];

const mostUsedPaymentMethodsOptions = [
  'Tarjetas',
  'Transferencias bancarias',
  'Saldo en billetera electrónica (Mercadopago, Naranja X, Modo)',
  'Rapipago/Pagofacil',
];

const onlineShoppingFrecuencyOptions = [
  'No compro online',
  '1 o 2 veces por año',
  'Cada 2 o 3 meses',
  '1 o 2 veces al mes',
  '3 a 5 veces al mes',
  'Más de 5 veces al mes',
];

const yearListOptions = [] as number[];
const currentYear = new Date().getFullYear();

for (let i = currentYear - 70; i <= currentYear - 13; i++) {
  yearListOptions.push(i);
}

const YesAndNoOptions = [
  { value: 1, label: 'Si' },
  { value: 2, label: 'No' },
];

export {
  mostInterestedDiscountsOptions,
  mostInterestedAreasOptions,
  mostInterestedSolutionsOptions,
  mostUsedPaymentMethodsOptions,
  onlineShoppingFrecuencyOptions,
  yearListOptions,
  YesAndNoOptions,
};
