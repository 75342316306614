import {
  VStack,
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useToast,
  Box,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useForm } from 'react-hook-form';
import useRegisterContext from '../../hooks/useRegisterContext';
import saveRegister from '../../services/form';

export default function Step1() {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const toast = useToast();
  const { changeStep, setEmail } = useRegisterContext();

  const onSubmit = async (data: any) => {
    setLoading(true);
    await saveRegister(data);

    setEmail(data.email);
    toast({
      title: '¡Excelente! Te registraste con éxito',
      description: 'Pronto te estaremos avisando las novedades de Descuentas.',
      status: 'success',
      isClosable: true,
    });
    changeStep();
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)} width={{ base: '100%', md: 'auto' }}>
      <VStack gap={8} width="100%">
        <VStack
          gap={4}
          align={{ base: 'stretch', md: 'flex-start' }}
          flexDirection={{ base: 'column', md: 'row' }}
          width="100%"
        >
          <FormControl isInvalid={Boolean(errors.name)}>
            <FormLabel>Nombre</FormLabel>
            <Input
              {...register('name', {
                required: 'Por favor, ingresa tu nombre',
              })}
            />
            <FormErrorMessage>{errors.name && errors.name?.message?.toString()}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={Boolean(errors.email)} margin={'0 !important'}>
            <FormLabel>Correo electrónico</FormLabel>
            <Input
              {...register('email', {
                required: 'Por favor, ingresa tu correo electrónico',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Por favor, ingresa un email válido',
                },
              })}
            />
            <FormErrorMessage>{errors.email && errors.email?.message?.toString()}</FormErrorMessage>
          </FormControl>
        </VStack>
        <Button type="submit" isLoading={loading}>
          REGISTRARME
        </Button>
      </VStack>
    </Box>
  );
}
