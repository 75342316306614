import { LinkIcon } from '@chakra-ui/icons';
import { Box, Container, HStack, Text, VStack } from '@chakra-ui/react';
import { FaFacebookF, FaInstagram, FaTiktok, FaTwitter } from 'react-icons/fa';
import LinkToRegister from './LinkToRegister';

export default function Footer() {
  return (
    <Box background="black" paddingBlock={{ base: 8, md: 16 }}>
      <Container>
        <VStack
          justify="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          gap={4}
          align={{ base: 'center', md: 'start' }}
        >
          <Text
            color="primary"
            fontSize={25}
            fontFamily="Roboto Condensed"
            textTransform="uppercase"
          >
            Descuenta$
          </Text>
          <VStack
            flexDirection={{ base: 'column-reverse', md: 'column' }}
            align={{ base: 'center', md: 'end' }}
            gap={6}
          >
            <HStack align="center">
              <Text
                color="white"
                fontSize={18}
                fontFamily="Roboto Condensed"
                textTransform="uppercase"
                marginRight={4}
              >
                Síguenos
              </Text>
              <HStack gap={4}>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <LinkIcon as={FaFacebookF} color="white" />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <LinkIcon as={FaInstagram} color="white" />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <LinkIcon as={FaTiktok} color="white" />
                </a>
                <a href="https://facebook.com" target="_blank" rel="noreferrer">
                  <LinkIcon as={FaTwitter} color="white" />
                </a>
              </HStack>
            </HStack>
            <LinkToRegister />
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
}
