import { Box, Container, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import LinkToRegister from './LinkToRegister';

function Header({ button = true }) {
  return (
    <Box backgroundColor={'#000'} paddingBlock={4}>
      <Container>
        <HStack justifyContent="space-between">
          <Text
            color="white"
            fontSize={25}
            fontFamily="Roboto Condensed"
            textTransform="uppercase"
            as={Link}
            to="/"
          >
            Descuenta$
          </Text>
          {button && <LinkToRegister />}
        </HStack>
      </Container>
    </Box>
  );
}

export default Header;
