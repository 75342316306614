import { Button, ButtonProps } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

const LinkToRegister = (props: ButtonProps) => {
  return (
    <Button as={Link} to="/registro" {...props}>
      Instalar gratis
    </Button>
  );
};

export default LinkToRegister;
