import { Box, BoxProps, Container, HStack, Stack, Text, VStack } from '@chakra-ui/react';
import Header from '../components/Header';
import Section from '../components/Section';
import Step1 from './form-steps/Step1';
import Step2 from './form-steps/Step2';
import Step3 from './form-steps/Step3';
import { RegisterProvider } from '../context/register-context';
import useRegisterContext from '../hooks/useRegisterContext';
import Step4 from './form-steps/Step4';
import { useMemo } from 'react';
import { CheckIcon } from '@chakra-ui/icons';

const formsByStep = [<Step1 />, <Step2 />, <Step3 />, <Step4 />];

function Register() {
  return (
    <RegisterProvider>
      <Header button={false} />
      <Section
        minHeight={{ md: 'calc(100vh - 70px)' }}
        padding={{ base: 0, md: '80px 0' }}
        background="primary"
        margin={0}
      >
        <Container padding={0}>
          <Box marginX={{ md: '10vh' }}>
            <CurrentStep />
          </Box>
        </Container>
      </Section>
    </RegisterProvider>
  );
}

const CurrentStep = () => {
  const { step } = useRegisterContext();

  return (
    <Stack
      align="stretch"
      direction={{
        base: 'column',
        md: step === 0 ? 'column' : 'row',
      }}
    >
      <VStack
        background="black"
        padding={12}
        gap="24px"
        justify="center"
        flex={1}
        borderRadius={{
          md: step === 0 ? '15px 15px 0 0' : '15px 0 0 15px',
        }}
      >
        {step === 0 ? (
          <>
            <Text variant="title" textAlign="center">
              ¡Pronto estaremos lanzando Descuentas en Argentina!
            </Text>
            <Text variant="title" textAlign="center">
              y podrás empezar a recibir los mejores descuentos y reintegros en efectivo por tus
              compras.
            </Text>
            <Text variant="title" textAlign="center">
              Regístrate para usarlo
            </Text>
          </>
        ) : (
          <>
            <Text variant="title" fontSize={24} textAlign={{ base: 'center', md: 'left' }}>
              ¡GRACIAS POR TU REGISTRO! ESTAMOS TRABAJANDO PARA TI
            </Text>
            <Text variant="title" fontSize={24} textAlign={{ base: 'center', md: 'left' }}>
              AYÚDANOS A CONOCERTE COMPLETANDO ESTE CUESTIONARIO
            </Text>
          </>
        )}
      </VStack>
      <VStack
        background="white"
        padding={12}
        justify="center"
        gap={8}
        flex={1}
        margin="0px !important"
        borderRadius={{ md: step === 0 ? '0 0 15px 15px' : '0 15px 15px 0' }}
      >
        {step > 0 && <StepCounter />}
        {formsByStep[step]}
      </VStack>
    </Stack>
  );
};

const StepCounter = () => {
  const { step } = useRegisterContext();

  const totalSteps = useMemo(() => [1, 2, 3], []);

  const styleProps: BoxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '2px',
    height: '32px',
    width: '32px',
    borderRadius: 'full',
    fontWeight: 800,
  };

  return (
    <HStack gap={4} marginTop="-1em" justify={{ md: 'end' }} width="100%">
      {totalSteps.map((_step) => (
        <>
          <Box
            key={_step}
            {...styleProps}
            background={step >= _step ? 'primary' : 'blackAlpha.100'}
            color={step >= _step ? 'black' : 'blackAlpha.600'}
            borderColor={step >= _step ? 'primary' : 'blackAlpha.50'}
            opacity={step > _step ? 0.8 : 1}
          >
            {step > _step ? <CheckIcon /> : _step}
          </Box>
          {_step !== 3 && (
            <Box height={0.5} width={10} background={step > _step ? 'primary' : 'blackAlpha.200'} />
          )}
        </>
      ))}
    </HStack>
  );
};

export default Register;
