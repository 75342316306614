// import { useState } from 'react';
import { Checkbox, CheckboxGroup, FormLabel, Grid, Text } from '@chakra-ui/react';
import { Control, RegisterOptions, useController } from 'react-hook-form';

interface CheckboxProps {
  options: Array<string>;
  label: string;
  name: string;
  control: Control;
  rules: RegisterOptions;
}

function CustomCheckbox({ options, label, name, control, rules }: CheckboxProps) {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    rules,
  });

  return (
    <div>
      <FormLabel>
        {label}
        <Text opacity={0.7} fontSize="12px">
          (Máximo 3 opciones)
        </Text>
      </FormLabel>
      <CheckboxGroup onChange={onChange}>
        <Grid gridTemplateColumns={{ md: 'repeat(2, 1fr)' }} gap={2}>
          {options.map((option, index) => {
            const isDisabled = value?.length >= 3 && !value.includes(option);

            return (
              <Checkbox key={index} value={option} disabled={isDisabled}>
                {option}
              </Checkbox>
            );
          })}
        </Grid>
      </CheckboxGroup>
    </div>
  );
}

export default CustomCheckbox;
