import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: '#FFCC00',
  black: '#000',
  yellow: { 500: '#FFCC00' },
};

const styles = {
  breakpoints: {
    sm: '320px',
    md: '768px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
  },
  global: {
    body: {
      backgroundColor: '#fff',
      color: 'black',
      fontFamily: 'Roboto, system-ui',
    },
  },
};

const components = {
  Button: {
    defaultProps: {
      variant: 'primary',
    },
    variants: {
      primary: {
        backgroundColor: 'primary',
        color: 'black',
        _hover: {
          color: 'primary',
        },
      },
      black: {
        background: 'black',
        color: 'white',
        borderColor: 'black',
        _hover: {
          borderColor: 'black',
          color: 'black',
        },
      },
    },
    sizes: {
      xl: {
        fontSize: '19px',
        padding: '16px 24px',
      },
    },
    baseStyle: {
      borderRadius: '10px',
      padding: '10px 16px',
      backgroundColor: 'grey',
      color: 'black',
      fontFamily: 'Roboto Condensed',
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 800,
      letterSpacing: '0.05em',
      textDecoration: 'none',
      cursor: 'pointer',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderColor: 'primary',
      _hover: {
        backgroundColor: 'transparent',
      },
    },
  },
  Text: {
    baseStyle: {
      fontSize: {
        base: '16px',
        md: '22px',
      },
    },
    variants: {
      heading: {
        fontFamily: 'Roboto Condensed',
        fontSize: {
          base: '28px',
          md: '42px',
        },
        textTransform: 'uppercase',
      },
      highlight: {
        display: 'inline',
        fontSize: 'inherit',
        background: 'primary',
        color: 'black',
      },
      title: {
        fontFamily: 'Roboto Condensed',
        fontSize: '22px',
        textTransform: 'uppercase',
        color: 'white',
      },
    },
  },
  FormLabel: {
    baseStyle: {
      fontSize: '14px',
      marginBottom: '5px',
    },
  },
  Input: {
    variants: {
      outline: {
        field: {
          borderRadius: '10px',
          borderColor: 'black',
          _focus: {
            zIndex: 1,
            borderColor: 'primary',
            boxShadow: '0 0 0 1px ' + colors.primary,
          },
          _hover: {
            borderColor: 'grey',
          },
        },
      },
    },
  },
  Container: {
    baseStyle: {
      maxWidth: '1240px',
    },
  },
  Radio: {
    defaultProps: {
      colorScheme: 'yellow',
    },
  },
  Checkbox: {
    defaultProps: {
      colorScheme: 'yellow',
    },
  },
};

export default extendTheme({
  styles,
  colors,
  components,
});
