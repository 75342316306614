import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import './styles/index.scss';

import App from './App';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);
